import { createAction, props } from '@ngrx/store';

export interface ToastrAction {
  title?: string;
  message?: string;
}


export const AppLayoutActions = {
  loader : createAction('[App layout] Loader', props<{isLoading: boolean}>()),
  showToast: createAction('[Toastr] Show Toast', props<ToastrAction>()),
  showSuccessToast: createAction('[Toastr] Show Success Toast',props<ToastrAction>()),
  showInfoToast: createAction('[Toastr] Show Info Toast',props<ToastrAction>()),
  showWarningToast: createAction('[Toastr] Show Warning Toast',props<ToastrAction>()),
  showErrorToast: createAction('[Toastr] Show Error Toast',props<ToastrAction>()),
};