export class User {
    userGuid = "";
    userId = "";
    userName = "";
    userEmail = "";
  }

  export class DcodeUser {
    public firstName= "";
    public lastName= "";
    public name= "";
    public userName= "";
    public email= "";
    public isAdmin= false;
    public lastSeen= "";
  }
  

  export class UserRoles {
    roles: string[] = [];
  }

  export class UserProfile {
    displayName?= "";
    givenName?= "";
    id?= "";
    jobTitle?= "";
    mail?= "";
    mobilePhone?= "";
    officeLocation?= "";
    preferredLanguage?= "";
    surname?= "";
    userPrincipalName?= "";
  }